@import "../../styles/variables.scss";

.disclosures {
  -webkit-font-smoothing: antialiased;
  font-size: $fontSize_2xs;
  font-weight: $fontWeightMedium;
  letter-spacing: $letterSpacingTight;
  line-height: $lineHeight_normal;
  text-align: left;
  color: #a1a5b2;

  .linkButton {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }
}
