@import "/styles/variables.scss";

.downloadBtn {
  color: $black;
  font-size: rem(15px);
  font-weight: $fontWeightBold;
  line-height: rem(22px);
  padding: rem(8px) rem(30px);
}

.largeButton {
  font-size: rem(17px);
  letter-spacing: -0.01em;
  line-height: rem(19px);
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

@include media-breakpoint-up(md) {
  .largeButton {
    max-width: 275px;
  }
}
