@import "../../../styles/variables.scss";

.modalContent {
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  border: none;
  padding: 0 !important;
  text-align: left;
}

.heading {
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.035em;
  margin-bottom: 10px;
}

.subscribe {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0em;
  margin-bottom: 30px;
}

.sendLink {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.01em;
  margin-bottom: 15px;

  &.sent {
    color: $pink;
  }
}

.iPhone {
  margin-right: 4rem;
  position: relative;
  .closeButton {
    position: absolute;
    top: 1.5rem;
    right: -2.5rem;
    border: none;
    background: none;
  }
}

.closeButton {
  cursor: pointer;
}

.activationContent {
  padding: 50px 60px;
  color: $black;
}

.ig {
  margin-bottom: 0.75rem;
  position: relative;
  .cCode {
    position: absolute;
    font-weight: $fontWeightBold;
    color: $black;
    left: 15px;
    top: 12px;
  }
  div {
    max-width: 60%;
  }
  .igText {
    border: none;
    background: lighten($grey, 25%);
    font-weight: $fontWeightBold;
    padding: 0.75rem;
    color: $black;
    border-top-left-radius: 0.35rem;
    border-bottom-left-radius: 0.35rem;
    max-height: 48px;
  }

  .igInput {
    border: 1px solid rgba(21, 22, 30, 0.2);
    width: 225px;
    height: 40px;
    padding: 10px 0px 10px 40px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.015em;
  }

  .igBtn {
    background: $pink;
    color: $purple;
    padding: 12px 18px;
    text-align: center;
    border-radius: 20rem !important;
    border: none;
    font-weight: $fontWeightBold;
    position: relative;
    right: -1.5rem;
    margin-left: 8px;
    &.disabled {
      opacity: 0.55;
    }
  }
}

.label {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.02em;
  margin-bottom: 7px;
}

.legal {
  color: $legallyGray;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
}

.logos {
  display: flex;
  align-items: flex-end;
  .appStore,
  .playStore {
    margin-right: rem(10px);
  }
  .qrBtn {
    margin-left: 7px;
  }
}

.appStoreWrapper {
  display: block;
  .text {
    font-size: 13px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: -0.005em;
    margin-bottom: 10px;
  }
}

.playStoreWrapper {
  margin-top: 27px;
}

.qrWrap {
  position: relative;
  .fullQR {
    background: $white;
    padding: 1.5rem;
    border: none;
    -webkit-box-shadow: 4px 15px 21px 0 #00000026;
    box-shadow: 4px 15px 21px 0 #00000026;
    text-align: center;
    position: absolute;
    top: -185px;
    left: -62px;
    border-radius: 0.5rem;
    transition: all 0.25s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.qrBorder {
  border: 1px solid $black;
  position: absolute;
  left: -5px;
  top: -3px;
  width: calc(100% + 10px);
  height: calc(100% + 6px);
  background: transparent;
  .qrTop {
    position: absolute;
    top: -1px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    background: $white;
  }
  .qrBot {
    position: absolute;
    bottom: -1px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    background: $white;
  }
  .qrLeft {
    position: absolute;
    left: -1px;
    width: 3px;
    top: 50%;
    transform: translateY(-50%);
    height: 75%;
    background: $white;
  }
  .qrRight {
    position: absolute;
    right: -1px;
    width: 3px;
    top: 50%;
    transform: translateY(-50%);
    height: 75%;
    background: $white;
  }
}

@media (min-width: 992px) {
  .modalDialog {
    max-width: 992px !important;
  }
}
