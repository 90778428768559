@import "~bootstrap/scss/functions.scss";
@import "~bootstrap/scss/variables.scss";
@import "~bootstrap/scss/mixins.scss";

@import "./mixins.scss";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

// Colors
$purple: #470039;
$darkPurple: #59194d;
$citrine: #e6f200;
$pink: #ff8cdd;
$red: #ff3148;
$darkRed: #b20043;
$blue: #00415c;
$lightBlue: #00daff;
$teal: #00b9bf;
$green: #03c211;
$mediumGreen: #00c100;
$darkGreen: #005954;
$grey: #a1a5b3;
$black: #15161e;
$extraBlack: #0e0f18;
$white: #ffffff;
$legallyGray: #737378;
$lightGrey: #f8f8f8;
$lighterGrey: #ededed;
$onyx: #2c2d35;
$purpleRain: #a1a5b3;

$letterSpacingTighest: -0.06em;
$letterSpacingTighter: -0.05em;
$letterSpacing_3xs: -0.035em;
$letterSpacingTight: -0.02em;
$letterSpacingTighten: -0.01em;
$letterSpacingNormal: normal;
$letterSpacingWide: 0.5px;

$fontSize_5xs: 0.5rem;
$fontSize_4xs: 0.5625rem; // 9px
$fontSize_3xs: 0.625rem; // 10px
$fontSize_3xsm: 0.6875rem; // 11px
$fontSize_2xs: 0.75rem; // 12px
$fontSize_xs: 0.8125rem; // 13px
$fontSize_sm: 0.875rem; // 14px
$fontSize_base: 1rem; // 16px
$fontSize_lg: 1.125rem; // 18px
$fontSize_lgx: 1.25rem; // in between size: 20px
$fontSize_xl: 1.5rem; // 24px
$fontSize_2xl: 1.75rem; // 28px
$fontSize_3xl: 2rem; // 32px
$fontSize_4xl: 2.25rem; // 36px
$fontSize_5xl: 2.5rem; // 40px
$fontSize_6xl: 3.125rem; // 50px
$fontSize_7xl: 3.75rem; // 60px
$fontSize_8xl: 5.375rem; // 86px
$fontSize_9xl: 6.25rem; // 100px
$fontSize_10xl: 7.5rem; // 120px
$fontSize_11xl: 9.5rem; // 152px

$fontWeightNormal: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

$lineHeight_xxs: 0.76;
$lineHeight_xs: 1;
$lineHeight_sm: 1.05;
$lineHeight_normal: 1.2;
$lineHeight_lg: 1.44;
$lineHeight_lgx: 1.5;
$lineHeight_xl: 1.57;
$lineHeight_2xl: 1.75;

// Main Headings
$mhFontSize: 2.5rem;
$mhFontSize1200: 3.75rem;
$mhLetterSpacing: -1px;
$mhLineHeight: 2.5rem;
$mhLineHeight1200: 4rem;
$mhFontWeight: 700;
$mhColor: $purple;

// Underline Headings (eg. /reserve/tesla)
$uhFontSize: 1.6rem;
$uhTextDecoration: underline;
$uhFontWeight: 700;

// Bold subheadings (used for emphasis)
$bshFontSize: 1.4rem;
$bshFontWeight: 700;

// Buttons

// Purple Button (used for CTA's eg. /reserve/tesla)
$btnPurpleBG: $purple;
$btnPurpleFontSize: 1.2rem;
$btnPurpleBorder: none;
$btnPurpleBorderRadius: 5rem;
$btnPurpleFontWeight: 500;
$btnPurplePadding: 0.5rem 2rem;
$btnPurpleFontWeight: 500;
$btnPurpleColor: $white;

// Form elements (used on white bg, override in local module.scss files for dark bg)

// Fieldset
$fieldsetMarginBottom: 0.75rem;
$fieldsetTextAlign: left;
$fieldsetCitrineFontSize: 0.8rem;
$fieldsetCitrineFontWeight: 400;

// Legend
$legendColor: $purple;
$legendFontSize: 1rem;
$legendFontWeight: 600;
$legendMarginBottom: 0.5rem;

// Nested Form Group Labels (bootstrap override)
$formLabelFontWeight: 600;

// Form control (bootstrap overrides)
$fcBorderTop: none;
$fcBorderLeft: none;
$fcBorderRight: none;
$fcBorderBottom: 1px solid $black;
$fcBorderRadius: 0;
$fcPadding: 0.5rem 0;
$fcFontSize: 1rem;
$fcActiveBoxShadow: none;
$fcActiveOutline: none;

$fcrBorderBottomColor: $white;
$fcrColor: $white;

// Transitions
$transStandard: all 0.25s ease;

$zIndex_banner: 1000;
$zIndex_overlay: 1001;
$zIndex_modal: 1002;
