@import "../../styles/variables.scss";

.downloadBtn {
  background: transparent !important;
  border: transparent !important;
  box-shadow: none !important;
  color: $pink !important;
  letter-spacing: -0.01em;
  line-height: 20px;
  max-width: 350px;
  padding-left: 0px;
  padding-right: 0px;

  &:hover {
    text-decoration: underline;
  }
}

.mobileCTA {
  color: $black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  max-width: 650px;
  text-align: center;
  width: 100%;

  .divider {
    background: rgba($grey, 0.25);
    height: 1px;
  }

  .subtext {
    font-size: rem(18px);
    font-weight: $fontWeightMedium;
    margin-top: 2rem;
  }
}
