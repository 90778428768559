@import "/styles/variables.scss";

.qrWrap {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 85px;

  canvas {
    width: 85px;
    height: auto;
    position: relative;
    top: 3px;
  }
}

.qrBorder {
  border: 1px solid $black;
  position: absolute;
  left: -5px;
  top: -3px;
  width: calc(100% + 10px);
  height: calc(100% + 6px);
  background: transparent;

  .qrTop {
    position: absolute;
    top: -1px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    background: $white;
  }

  .qrBot {
    position: absolute;
    bottom: -1px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    background: $white;
  }

  .qrLeft {
    position: absolute;
    left: -1px;
    width: 3px;
    top: 50%;
    transform: translateY(-50%);
    height: 75%;
    background: $white;
  }

  .qrRight {
    position: absolute;
    right: -1px;
    width: 3px;
    top: 50%;
    transform: translateY(-50%);
    height: 75%;
    background: $white;
  }
}
