@import "../../../styles/variables";

.reservationForm {
  :global(.form-control)::placeholder {
    opacity: 0.5;
  }
}

.cardInfoContainer {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  &.hasErrors {
    border-color: $danger;
  }
}

.cardError {
  display: block;
}

.cardInfoCol {
  flex: 1;
}

.cardNumberField,
.expDateField,
.cvcField,
.zipField {
  border-radius: 0px;

  &:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
}

.cardNumberField {
  border-width: 0;
}

.expDateField {
  border: none;
}

.cvcField {
  border-bottom: none;
  border-top: none;
}

.zipField {
  border: none;
}

.formGroupLabel {
  color: $purple;
  font-weight: $fontWeightMedium;
  letter-spacing: $letterSpacingNormal;
}

.mobileRefundText {
  color: $purple;
  font-size: $fontSize_2xs;
  font-weight: $fontWeightBold;
  line-height: 2em;
  letter-spacing: $letterSpacingNormal;
  margin-top: 0.5rem;
}

.reserveButton {
  background: $pink;
  border-radius: 40px;
  border: none;
  color: $purple;
  display: block;
  font-size: $fontSize_base;
  font-weight: $fontWeightBold;
  padding: 10px 1rem;
  line-height: $lineHeight_normal;
  width: 100%;

  &:disabled {
    opacity: 0.5;
  }
}

.ccLogoWrap {
  display: flex;
  align-items: center;
  background: $white;
  border: 1px solid #ced4da;
  .ccInput {
    flex: 0 0 75%;
    height: 100%;
  }
  .ccLogos {
    flex: 0 0 35%;
    height: 100%;
    ul {
      margin: 0 0 0 -1rem;
      padding: 0;
      display: flex;
      align-items: center;
      height: 100%;
      li {
        flex: 0 0 20%;
        padding: 0 0.15rem;
        list-style: none;
        opacity: 1;
        transition: all 0.25s ease;
        &.faded {
          opacity: 0.25;
        }
      }
    }
  }
}

.refundableBtn,
.refundableBtn:focus,
.refundableBtn:hover {
  padding: 0;
  color: $purple;
  font-size: $fontSize_2xs;
  font-weight: 700;
  text-decoration-color: $pink;
}

.submitButton {
  background-color: $pink;
}

.legal {
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 1.2;
  text-align: left;
  color: #a1a5b2;
}

.frWrap {
  letter-spacing: $letterSpacingNormal;
  .frTitle {
    font-size: $fontSize_sm;
    font-weight: $fontWeightBold;
  }
  .frBtn {
    display: inline-block;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $pink;
    border-left: none;
    color: $purple;
    font-size: $fontSize_sm;
    font-weight: bold;
    background: none;
    padding: 0;
    margin: 0 0.3rem 0 0;
    cursor: pointer;
  }
}

@include media-breakpoint-up(md) {
  .darkMode {
    .formGroupLabel {
      color: $purple;
    }

    .mobileRefundText {
      display: none;
    }
  }

  .mobileRefundText {
    line-height: 1em;
    margin-top: 0.75rem;
  }
  .ccLogoWrap {
    .ccInput {
      flex: 0 0 70%;
      height: 100%;
    }
    .ccLogos {
      flex: 0 0 30%;
      ul {
        margin: 0;
      }
    }
  }
}
