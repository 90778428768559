@use "sass:math";

$html-font-size: 16px;

@function stripUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

@mixin override-link-defaults($color: #0d6efd) {
  a {
    color: $color;

    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin reset-page-content {
  -webkit-font-smoothing: antialiased;
  --bs-gutter-x: 40px;
  color: $black;
  letter-spacing: normal;
  text-align: left;

  @include media-breakpoint-up(lg) {
    --bs-gutter-x: 60px;
  }
}
