@import "/styles/variables.scss";

.confirmScreenContainer,
.formContainer {
  --bs-gutter-y: 20px;
}

.formContainer {
  margin-bottom: 60px;

  & :global(.form-label) {
    font-weight: 500;
    font-size: rem(13px);
    line-height: rem(15px);
    letter-spacing: -0.02em;
  }
}

.heading {
  font-weight: 700;
  font-size: rem(50px);
  line-height: rem(52px);
  letter-spacing: -0.035em;
  margin-bottom: 15px;
}

.subheading {
  font-size: rem(15px);
  line-height: rem(22px);
  margin-bottom: 30px;
}

.formRow {
  --bs-gutter-y: 20px;
}

.vehicleUnavailable {
  color: $pink;
  font-weight: 500;
  font-size: rem(15px);
  line-height: rem(20px);
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.disclosure {
  font-weight: 500;
  font-size: rem(11px);
  line-height: rem(13px);
  letter-spacing: -0.02em;
  color: $legallyGray;
  margin-bottom: 25px;

  a {
    color: $pink;
    text-decoration: underline;
  }
}

.submitError {
  margin-top: 0.5rem;

  a {
    color: $pink;
  }
}

.secondaryHeading {
  font-weight: 700;
  font-size: rem(30px);
  line-height: rem(30px);
  letter-spacing: -1px;
  margin-bottom: 15px;
}

.secondarySubheading {
  font-weight: 700;
  font-size: rem(17px);
  line-height: rem(19px);
  letter-spacing: -0.01em;
  margin-bottom: 25px;
}

.resendMsg {
  font-size: rem(15px);
  line-height: rem(22px);
  margin-bottom: 25px;
}

.submitButton,
.downloadButton {
  font-weight: 700;
  height: 48px;
  font-size: rem(17px);
  line-height: rem(19px);
  letter-spacing: -0.01em;
}

.successMessage {
  color: $purple;
  margin-top: 1rem;
  text-align: center;
}
