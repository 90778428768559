@import "/styles/variables.scss";

.navItem,
.navDropdown :global(.nav-link) {
  font-weight: $fontWeightBold;
  font-size: rem(18px);
  letter-spacing: -0.01em;
}

@include media-breakpoint-down(lg) {
  .nav {
    margin-top: 1rem;
    text-align: left;
  }

  .navItem,
  .navDropdown :global(.nav-link) {
    padding-bottom: rem(20px);
    padding-top: rem(20px);
  }

  .navDropdown {
    :global(.dropdown-menu) {
      border: 0px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .navDropdownItem {
    font-size: rem(15px);
    font-weight: $fontWeightMedium;
    padding-bottom: rem(12px);
    padding-top: rem(12px);

    &:global(.active),
    &:active {
      background-color: $purple;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navItem,
  .navDropdown :global(.nav-link) {
    font-size: rem(15px);
    line-height: rem(18px);
  }

  .navItem,
  .navDropdown :global(.nav-link) {
    padding-left: rem(10px) !important;
    padding-right: rem(10px) !important;
  }

  .nav {
    :global(.nav-link):global(.active) {
      position: relative;
    }

    :global(.nav-link):global(.active):after {
      background-color: $citrine;
      bottom: 5px;
      content: "";
      height: 2px;
      left: rem(10px);
      position: absolute;
      right: rem(10px);
    }
  }
}

@include media-breakpoint-up(xl) {
  .navItem,
  .navDropdown :global(.nav-link) {
    padding-left: rem(20px) !important;
    padding-right: rem(20px) !important;
  }

  .nav {
    :global(.nav-link):global(.active):after {
      left: rem(20px);
      right: rem(20px);
    }
  }
}
