@import "/styles/variables.scss";

.logoContainer {
  display: flex;
  min-width: 150px;
  padding-bottom: rem(8px);
  padding-top: rem(8px);
}

@include media-breakpoint-up(md) {
  .logoContainer {
    min-width: 180px;
    padding-bottom: rem(24px);
    padding-top: rem(24px);
  }
}
