@import "../../../styles/variables.scss";

.modalContent {
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  border-color: transparent;
  padding: 2rem;
  text-align: left;
}

.header {
  padding: 0;
  border: none;
  padding-bottom: 1rem;
}

.closeBtn {
  cursor: pointer;
  display: flex;
  margin-right: 0.5rem;
}

.title {
  color: $black;
  font-size: $fontSize_xl;
  font-weight: $fontWeightMedium;
  letter-spacing: $letterSpacingTighter;
  line-height: $lineHeight_normal;
}

.content {
  color: #000;
  font-size: $fontSize_xs;
  line-height: $fontSize_lgx;
}
