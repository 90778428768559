@import "/styles/variables.scss";

.modalContent {
  @include reset-page-content;
}

.modalBody {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 60px;
}

@include media-breakpoint-up(lg) {
  .modalBody {
    padding-left: 60px;
    padding-right: 60px;
  }
}
