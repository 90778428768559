@import "../../styles/variables.scss";

.navBar {
  --bs-gutter-x: 20px;
  background-color: $white;
  transition: background-color 0.25s ease-in;

  .navBarToggle {
    border: none;
    box-shadow: none;
  }

  :global(.nav-link) {
    color: $black !important;
  }

  &.theme_dark,
  &.theme_transparent {
    :global(.nav-link) {
      color: $white !important;
    }
  }

  &.theme_dark {
    background-color: $onyx;
  }

  &.theme_transparent {
    background-color: transparent;
  }
}

.nowrap {
  position: relative;
  top: 5px;
}

@include media-breakpoint-up(md) {
  .nowrap {
    top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .navBar {
    --bs-gutter-x: 60px;
    flex-wrap: wrap;
  }

  .nowrap {
    flex-wrap: nowrap !important;
  }

  .navBarCollapse {
    flex-shrink: 0;
    margin-top: 10px;
  }
}

@include media-breakpoint-down(lg) {
  .navBarCollapse:global(.collapsing) {
    display: none;
  }

  .navBar {
    padding-top: 1rem;
    z-index: $zIndex_banner;
  }

  .hasOpenedMenu {
    padding-bottom: 2rem;
    margin-top: 0px;

    .navBarCollapse {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 126px);
    }

    .navAppDownload {
      margin-top: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .hasOpenedMenu {
    .navBarCollapse {
      min-height: calc(100vh - 94px);
    }
  }
}
