@import "../../../styles/variables.scss";

.confirmFormBox {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.getStartedWrapper {
  width: 100%;
  text-align: left;
}

.formTitle {
  text-align: left;
  // font-size: $fontSize_xl;
  font-weight: $fontWeightBold;
  color: $black;
  letter-spacing: $letterSpacingTight;
  line-height: 2;
  font-family: "DM Sans";
  font-style: normal;
  font-size: 50px;
  line-height: 52px;
  /* or 104% */

  letter-spacing: -0.035em;
}

.boldText {
  text-align: left;
  font-size: $fontSize_2xs;
  font-weight: $fontWeightMedium;
  color: $black;
  line-height: 1.4;
}

.fullyRefundableLink {
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.orText {
  color: #a1a5b2;
  font-size: $fontSize_base;
}

.textGrey,
.disclosure {
  text-align: left;
  color: #a1a5b2;
  font-size: $fontSize_3xs;
}

.reservationForm {
  :global(.form-control)::placeholder {
    opacity: 0.5;
  }
}

.cardInfoContainer {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  &.hasErrors {
    border-color: $danger;
  }
}

.cardError {
  display: block;
}

.cardInfoCol {
  flex: 1;
}

.cardNumberField,
.expDateField,
.cvcField,
.zipField {
  border-radius: 0px;

  &:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
}

.cardNumberField {
  // border-width: 0 0 1px 0;
  border: 1px solid #ced4da;
}

.expDateField {
  border: none;
}

.cvcField {
  border-bottom: none;
  border-top: none;
}

.zipField {
  border: none;
}

.formGroupLabel {
  font-weight: $fontWeightMedium;
  letter-spacing: $letterSpacingNormal;
  font-weight: 700;
  font-size: $fontSize_base;
  line-height: $fontSize_lg;
  color: $black;
}

.mobileRefundText {
  color: $purple;
  font-size: $fontSize_2xs;
  font-weight: $fontWeightBold;
  line-height: 2em;
  letter-spacing: $letterSpacingNormal;
  margin-top: 0.5rem;
}

.reserveButton {
  background: $pink;
  border-radius: 40px;
  border: none;
  color: $purple;
  display: block;
  font-size: $fontSize_base;
  font-weight: $fontWeightBold;
  padding: 10px 1rem;
  line-height: $lineHeight_normal;
  width: 100%;

  &:disabled {
    opacity: 0.5;
  }
}

.ccLogoWrap {
  display: flex;
  align-items: center;
  // background: $white;
  border: 1px solid #ced4da;
  .ccInput {
    flex: 0 0 53%;
    height: 100%;
    border: 1px solid #ced4da;
  }
  .ccLogos {
    flex: 0 0 47%;
    border-bottom: 1px solid #ced4da;
    height: 100%;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      height: 100%;
      li {
        flex: 0 0 20%;
        padding: 0 0.15rem;
        list-style: none;
        opacity: 1;
        transition: all 0.25s ease;
        &.faded {
          opacity: 0.25;
        }
      }
    }
  }
}

.refundableBtn,
.refundableBtn:focus,
.refundableBtn:hover {
  padding: 0;
  color: $purple;
  font-size: $fontSize_2xs;
  font-weight: 700;
  text-decoration-color: $pink;
}

.submitButton,
.submitButton:hover,
.submitButton:focus {
  background-color: $pink;
  border-color: $pink;
  display: flex;
  justify-content: space-around;
}

.WelcomeHeader {
  color: $black;
  font-weight: $fontWeightBold;
  line-height: 1.1;
  font-size: 50px;
  line-height: 52px;
  letter-spacing: -0.035em;
}

.formGroup {
  display: flex;
}

.welcomeMessage {
  color: $black;
  letter-spacing: $letterSpacingTight;
  line-height: $lineHeight_normal;
}

.welcomeConfirmed {
  font-size: $fontSize_lg;
  font-weight: $fontWeightMedium;
}

.welcomeRefundableMessage {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-top: 30px;
}

.appDownloadMsg {
  color: $purple;
  margin-top: 0.5rem;
  text-align: center;
}

.sentALink {
  font-weight: 700;
  font-size: 17px;
  line-height: 19px;
  margin-top: 10px;
}

.checkTexts {
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #15161e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.resend {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #15161e;
  margin-top: 20px;
}

@include media-breakpoint-up(md) {
  .darkMode {
    .formGroupLabel {
      color: $purple;
    }

    .mobileRefundText {
      display: none;
    }
  }

  .mobileRefundText {
    line-height: 1em;
    margin-top: 0.75rem;
  }
}
